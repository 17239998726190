<template>
  <div class="search-content">
    <form class="search-box" action="/">
      <Search
        v-model="text"
        show-action
        autofocus
        placeholder="请输入搜索关键词"
        left-icon=""
        @input="handelSearch"
        @cancel="handelRouteBack"
      />
    </form>

    <!-- 结果列表 -->
    <ul v-if="resultListHighLight.length" class="list">
      <li v-for="(item, index) in resultListHighLight" :key="index" v-html="item.titleHighlights"></li>
    </ul>

    <!-- 缺省页 -->
    <Empty v-else imgWidth="0.32rem" imgHeight="0.32rem" :image="noInfo" description="暂无信息，请重新输入" />
  </div>
</template>

<script>
import { Search, Icon } from 'vant';
import { defineComponent, ref, computed } from '@vue/composition-api';
import Empty from '@components/Empty/index.vue';

export default defineComponent({
  name: 'Source',
  components: { Search, Empty, Icon },
  setup(_, ctx) {
    // 缺省页图片
    // const noInfo = require("@/assets/icon_noInfo.png");
    const noInfo = '/img/icon_noInfo.8448eedf.png';
    // 搜索内容
    const text = ref('');
    // 搜索结果列表
    const resultList = ref([
      {
        title: '单元格'
      },
      {
        title: '单元楼'
      }
    ]);
    const resultListHighLight = computed(() => {
      // 将列表遍历，将搜索内容高亮
      resultList.value.map(item => {
        for (let key in item) {
          if (key === 'title') {
            let replaceReg = new RegExp(text.value, 'g'); // 匹配关键字正则
            // 如果有值才高亮处理，否则空字符串也会当着分隔符
            if (text.value) {
              let replaceString = '<span class="highlights-text">' + text.value + '</span>'; // 高亮替换v-html值
              item[key + 'Highlights'] = item[key].replace(replaceReg, replaceString); // 开始替换
            }
          }
        }
        return item;
      });
      return text.value ? resultList.value : [];
    });
    const handelSearch = () => {};

    // 返回上一页
    const handelRouteBack = () => {
      // 如果是在客户端环境，调用客户端返回方法
      ctx.root.$routerBack.call(ctx.root._router);
    };

    return {
      noInfo,
      text,
      resultList,
      resultListHighLight,
      handelSearch,
      handelRouteBack
    };
  }
});
</script>
<style lang="less" scoped>
.search-content {
  overflow: hidden;
  .search-box {
    position: fixed;
    z-index: 99999;
    width: 100%;
  }
  .list {
    padding: 0 0.12rem;
    margin-top: 0.44rem;
    li {
      border-bottom: 0.01rem solid #ebebeb;
      height: 0.48rem;
      line-height: 0.52rem;
      color: #666;
      font-size: 0.16rem;
      padding-right: 0.12rem;
      background: url('../../assets/icon_back.png') right center no-repeat;
    }
  }
}
</style>
<style lang="less">
.highlights-text {
  color: #1679ff;
}
.van-search__content {
  border: 0.01rem solid #bcbcbc;
  height: 0.3rem;
  border-radius: 0.06rem;
}
.van-search {
  padding: 0.07rem 0 0.07rem 0.12rem;
  .van-cell {
    padding: 0.05rem 0;
  }
  .van-field__body {
    height: 100%;
  }
  .van-field__control {
    height: 100%;
    width: 90%;
    font-weight: 600;
  }
  .van-icon-search {
    font-size: 0.2rem;
    color: #999;
  }
  .van-icon-clear {
    font-size: 0.15rem;
    color: #1679ff;
    padding-right: 0.16rem;
  }
  .van-search__action {
    color: #1677ff;
    font-size: 0.16rem;
    padding: 0 0.12rem;
    font-weight: 600;
  }
}
</style>
